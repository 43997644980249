html,
body,
.app {
  height: 100vh;
  background: rgb(255, 255, 255);
  padding: 1em;
}

/* Messages.js */
.messages {
  height: 62vh;
  overflow-y: scroll;
}

/* MessageForm.js */
.message__form {
  position: fixed !important;
  bottom: 1em;
  margin-left: 320px !important;
  left: 0;
  right: 1em;
  z-index: 200;
}

/* Message.js */
.message__self {
  border-left: 2px solid orange;
  padding-left: 8px;
}

.message__image {
  padding: 1em;
}

/* ProgressBar.js */
.progress__bar {
  margin: 0.3em 0 0 0 !important;
}

.sidebar-icon {
  margin-right: 10px !important;
}
.sidebar-logo-alignment-container {
  width: 100%;
  flex-grow: 0 !important;
  align-items: center;
  margin: 25px 0px 50px 3px;
}

.sidebar-item-alignment-container {
  display: flex;
  align-items: center;
}

.sidebar-user-alignment-container {
  flex-grow: 0 !important;
  width: 100%;
  align-items: left;
  display: inline-block;
  margin: 0px 0px 20px 0px;
}

.float-left {
  float: left;
  display: flex;
}
.float-left-text {
  float: left;
  display: flex;
  height: 40px;
  padding: 5px 0;
}
.ui.tabular.menu .item:hover {
  background-color: rgb(34, 64, 131) !important;
  color: rgb(255, 255, 255) !important;
}
.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
}
.image-hover {
  transform: scale(1, 1);
  transition: 1s;
}
.image-hover:hover {
  transform: scale(2, 2);
  z-index: 10000;
}
